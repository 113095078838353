import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Slider from "react-input-slider";
import {
  colors,
  targetNetwork,
  useTitle,
  ExternalLink,
  EthereumContext,
  SaleStatus,
  icons,
  Divider,
  constants,
  ParticlesBackground,
  Button as MintButton,
  Loading,
  Status,
  log,
  processError,
  useTheme,
} from "../index";
import openseaLogo from "../../images/opensea.svg";
import featuredGif from "../../images/holy.gif";

const MintPageSection = styled.div`
  display: flex;
  flex: 1;
  padding: 45px;
  width: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const MintFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const MintStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const MintTitleFrame = styled.div``;
const MintTitle = styled.h1`
  font-family: "${constants.fonts.title}";
  text-shadow: 3px 1px 3px #000000, 3px 1px 12px #000000;
  letter-spacing: 2px;
  text-align: center;
  color: white;
  font-weight: 900;
`;

const MintImageFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MintImage = styled.img`
  border: 2px solid white;
  background-color: #79b4e0;
  margin: 0px 20px 10px 20px;
  width: 85%;
  height: auto;
  border-radius: 20px;
  box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
    2px 1px 3px rgba(0, 0, 0, 0);
`;

const MintQuantityFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 20px 0px;
`;
const MintQuantitySlider = styled(Slider)`
  div:first-child {
    box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
      2px 1px 3px rgba(0, 0, 0, 0);
  }
`;
const MintQuantity = styled.div`
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  min-width: 50px;
  background-color: white;
  border-radius: 12px;
  margin-left: 20px;
  box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
    2px 1px 3px rgba(0, 0, 0, 0);
`;

const MintButtonFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddOnButtonFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const MintSupplyFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpenseaLogo = styled.img`
  height: auto;
  width: 100px;
  margin-left: 4px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Mint: React.FunctionComponent = () => {
  useTitle({ page: `Mint` });
  const { triggerSuccess } = useTheme()!;
  const [mintQuantity, setMintQuantity] = useState({ x: 1 });
  const {
    contract,
    contractNode,
    contractState,
    account,
    isOnWhiteList,
    hexProof,
  } = useContext(EthereumContext);

  const onWithdraw = async () => {
    contract!
      .withdraw()
      .then((data: any) => {
        console.log({ data });
        toast.dark(
          <ExternalLink
            href={`${targetNetwork.blockExplorer}tx/${data.hash}`}
            target="_BLANK"
            rel="noreferrer"
          >
            {`Withdrew funds! ✅ Click to view transaction on Etherscan 🔗`}
          </ExternalLink>
        );
      })
      .catch((error: any) => processError(error));
  };

  const onMint = async () => {
    const saleState: number = await contractNode!.saleState();
    if (saleState === 0) return toast.error(`The sale has not started yet.`);
    if (saleState === 1 && !isOnWhiteList)
      return toast.error(`You are not on the whitelist.`);
    if (saleState === 3) return toast.error(`Sorry, the sale is sold out!`);
    const maxSupply: ethers.BigNumber = await contractNode!.maxSupply();
    const totalSupply: ethers.BigNumber = await contractNode!.totalSupply();
    const remainingSupply: ethers.BigNumber = maxSupply.sub(totalSupply);
    const mintQty: ethers.BigNumber = ethers.BigNumber.from(mintQuantity.x);
    const sufficientQtyRemain: boolean = remainingSupply.gte(mintQty);
    // console.log(`Attempting to mint ${mintQty.toNumber()} token(s)..`);
    // console.log(`Remaining supply: ${remainingSupply.toNumber()}`);
    // console.log(`sufficientQtyRemain: ${sufficientQtyRemain}!`);
    if (!sufficientQtyRemain)
      return toast.error(
        `Error! Only ${remainingSupply.toNumber()} ${
          constants.token.symbol
        } remain! ⚠`
      );

    const price: ethers.BigNumber = await contractNode!.price();
    // console.log(`Price each ETH: ${price}!`);
    // console.log(`Total cost ETH: ${totalCost}!`);
    const symbol: string = await contractNode!.symbol();
    const totalCost: ethers.BigNumber = price.mul(mintQty);
    //increase gasLimit to prevent failed tx's due to out of gas
    let estimation: ethers.BigNumber | undefined = undefined;

    try {
      if (saleState === 1) {
        estimation = await contract!.estimateGas.mintWhitelist(
          mintQty,
          hexProof,
          {
            value: totalCost,
          }
        );
      } else {
        estimation = await contract!.estimateGas.mintPublic(mintQty, {
          value: totalCost,
        });
      }

      // console.log('Gas estimate:', estimation.toNumber());
    } catch (err: any) {
      processError(err);
    }

    if (!estimation) return;
    if (!hexProof) return;

    let prom: Promise<any>;

    if (saleState === 1) {
      prom = contract!.mintWhitelist(mintQty, hexProof, {
        value: totalCost,
        gasLimit: Math.floor(estimation.toNumber() * 1.2),
      });
    } else {
      prom = contract!.mintPublic(mintQty, {
        value: totalCost,
        gasLimit: Math.floor(estimation.toNumber() * 1.2),
      });
    }

    prom
      .then((data: any) => {
        const { gtag } = window as any;
        gtag("event", "mint", { event_category: "mint" });
        log(data);
        triggerSuccess();
        toast.dark(
          <ExternalLink
            href={`${targetNetwork.blockExplorer}tx/${data.hash}`}
            target="_BLANK"
            rel="noreferrer"
          >
            Minted {mintQty.toNumber()} {symbol}
            {"! "}
            {`Click to view transaction on Etherscan 🔗`}
          </ExternalLink>,
          { autoClose: 15000 }
        );
      })
      .catch((error: any) => processError(error));
  };
  const FaTwitterSquare = icons.FaTwitterSquare;
  return (
    <React.Fragment>
      <ParticlesBackground />
      <MintPageSection className="noselect">
        <MintFrame>
          <MintTitleFrame>
            {contractState && (
              <MintTitle>
                {contractState.saleState !== 3
                  ? `Mint ${constants.projectName}!`
                  : `All ${constants.token.symbol} have been minted!`}
              </MintTitle>
            )}
            <MintStatus>
              <SaleStatus />
            </MintStatus>

            {/* Display remaining whitelist supply  */}
            {contractState && contractState.saleState === 1 && (
              <MintStatus className="mb-3">
                <Status>
                  {contractState.counter && contractState.maxTokensInPresale ? (
                    `PRESALE: ${
                      contractState.maxTokensInPresale - contractState.counter
                    }/${contractState.maxTokensInPresale}`
                  ) : (
                    <Loading text="supply" />
                  )}
                </Status>
              </MintStatus>
            )}
          </MintTitleFrame>
          <MintImageFrame>
            <MintImage src={featuredGif} alt={constants.projectName} />
          </MintImageFrame>

          {contractState &&
          contractState.price &&
          contractState.MAX_MINT &&
          contractState.saleState &&
          ((contractState.saleState === 1 && isOnWhiteList) ||
            contractState.saleState === 2) ? (
            <>
              {contractState.saleState === 1 &&
                contractState.presaleMintsRemaining !== null &&
                contractState.maxMintPerAddress !== null && (
                  <>
                    <MintSupplyFrame>
                      <Status>
                        You're on the whitelist!{" "}
                        {contractState.presaleMintsRemaining}/
                        {contractState.maxMintPerAddress} Mints Available
                      </Status>
                    </MintSupplyFrame>
                  </>
                )}
              {/* Slider to select quantity to mint */}
              <MintQuantityFrame>
                <MintQuantitySlider
                  axis="x"
                  xmin={1}
                  xmax={contractState.MAX_MINT!}
                  xstep={1}
                  x={mintQuantity.x}
                  onChange={({ x }: { x: number }) =>
                    setMintQuantity((state: any) => ({ ...state, x }))
                  }
                  styles={{
                    track: {
                      backgroundColor: "#ffffff",
                    },
                    active: {
                      border: "2px solid white",
                      background: `background: rgb(196,51,33);
										background: linear-gradient( 0deg, rgba(196,51,33,1) 0%, rgba(235,56,34,1) 100% );`,
                    },
                    thumb: {
                      width: 30,
                      height: 30,
                    },
                    disabled: {
                      opacity: 0.5,
                    },
                  }}
                />
                <MintQuantity>{mintQuantity.x}</MintQuantity>
              </MintQuantityFrame>
              {/* Submit mint button */}
              <MintButtonFrame>
                <MintButton
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                  onClick={onMint}
                  style={{ marginBottom: "1rem" }}
                >
                  MINT (
                  {ethers.utils.formatEther(
                    contractState.price.mul(mintQuantity.x)
                  )}
                  Ξ)
                </MintButton>
              </MintButtonFrame>
            </>
          ) : (
            <>
              {contractState && contractState.saleState === 1 && (
                <>
                  <Status>Your address was not found on the whitelist.</Status>
                  <Status>Public sale goes live 2/22/22 at 22:22 UTC</Status>
                </>
              )}
            </>
          )}

          {/* Display remaining supply  */}
          <MintSupplyFrame>
            <Status>
              {contractState &&
              contractState.MAX_SUPPLY &&
              contractState.supplyRemaining ? (
                `${
                  contractState.supplyRemaining
                }/${contractState.MAX_SUPPLY.toNumber()} Remaining`
              ) : (
                <Loading text="supply" />
              )}
            </Status>
          </MintSupplyFrame>

          {/* Check if user is connected and has a balance, if so then display holder only features */}
          {contractState &&
          account &&
          contractState.balanceOf &&
          contractState.balanceOf > 0 ? (
            <React.Fragment>
              <MintTitleFrame>
                <Divider
                  title="#THEHOLYONES 🙌🖖"
                  color="white"
                  shadow={true}
                />
              </MintTitleFrame>

              {/* Prepared Tweet */}
              <AddOnButtonFrame>
                <MintButton
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                  onClick={() =>
                    window.open(
                      `http://twitter.com/share?text=${
                        constants.shareTweet.text
                      }&url=${
                        constants.shareTweet.url
                      }&hashtags=${constants.shareTweet.hashtags.join(",")}
											`,
                      "",
                      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600"
                    )
                  }
                >
                  <span style={{ fontSize: 18 }}>
                    <FaTwitterSquare />
                  </span>
                  &nbsp;&nbsp;
                  <span>Tweet about your {constants.token.symbol}</span>
                </MintButton>
              </AddOnButtonFrame>

              {/* Link to users Opensea activity */}
              {targetNetwork.opensea && (
                <AddOnButtonFrame>
                  <MintButton
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.1 }}
                    onClick={() =>
                      window.open(
                        `${targetNetwork.opensea}collection/${constants.socials.opensea}?tab=activity`
                      )
                    }
                  >
                    View activity on <OpenseaLogo src={openseaLogo} />
                  </MintButton>
                </AddOnButtonFrame>
              )}

              {/* Link to inventory page */}
              <StyledLink to="/inventory">
                <AddOnButtonFrame>
                  <MintButton
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.1 }}
                  >
                    <span style={{ fontSize: 18 }}>{/* <!--icon--> */}</span>
                    &nbsp;&nbsp; View your Inventory
                  </MintButton>
                </AddOnButtonFrame>
              </StyledLink>
            </React.Fragment>
          ) : (
            <></>
          )}

          {/* Withdrawal button for dev */}
          {contractState &&
          contractState.owner &&
          account &&
          contractState.owner === account ? (
            <React.Fragment>
              <MintTitleFrame>
                <Divider title="Owner Only" color="white" shadow={true} />
              </MintTitleFrame>
              <MintButtonFrame>
                <MintButton
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                  onClick={onWithdraw}
                >
                  WITHDRAW
                </MintButton>
              </MintButtonFrame>
            </React.Fragment>
          ) : (
            <></>
          )}
        </MintFrame>
      </MintPageSection>
    </React.Fragment>
  );
};
