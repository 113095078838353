import { useEffect, useState, FC, ReactElement } from 'react';
import { icons, UnstyledA } from '../index';

type ChildProps = {
	src: string;
	alt: string;
};
export const LazyImage: FC<ChildProps> = ({ src, alt }): ReactElement => {
	const [loaded, setLoaded] = useState<boolean>(false);

	const handleLoad = () => {
		setLoaded(true);
	};

	useEffect(() => {
		const img = new Image();
		img.onload = handleLoad.bind(this);
		img.src = src!;
	}, []);

	const FaSpinner = icons.FaSpinner;
	return loaded ? (
		<img alt={alt} src={src} />
	) : (
		<div
			style={{
				width: '285px',
				height: '285px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<span>
				<FaSpinner className='icon-spin' style={{ marginRight: 5 }} />
				&nbsp;Loading...
			</span>
		</div>
	);
};

export const LazyImageCardTop: FC<ChildProps> = ({
	src,
	alt,
}): ReactElement => {
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		let mounted = true;
		const handleLoad = () => {
			if (mounted) {
				setLoaded(true);
			}
		};
		const img = new Image();
		img.onload = handleLoad.bind(this);
		img.src = src!;
		return function cleanup() {
			mounted = false;
		};
	}, []);

	const FaSpinner = icons.FaSpinner;
	return loaded ? (
		<UnstyledA href={src} target='_BLANK'>
			<img
				className='card-img-top'
				alt={alt}
				src={src}
				style={{ border: 'none' }}
			/>
		</UnstyledA>
	) : (
		<div
			style={{
				width: '285px',
				height: '285px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<span>
				<FaSpinner className='icon-spin' style={{ marginRight: 5 }} />
				&nbsp;Loading...
			</span>
		</div>
	);
};
