import { useEffect } from 'react';
import { constants } from '../index';

interface IUseTitle {
	page: string;
}

export const useTitle = ({ page }: IUseTitle): void => {
	useEffect(() => {
		document.title = `${constants.logo} ${page && `| ${page}`}`;
	}, [page]);
};
