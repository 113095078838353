import React from 'react';
import { useCookies } from 'react-cookie';
import { constants } from '../constants';

const cookieName = `${constants.cookiePrefix}-theme`;

export const lightTheme = 'light';
export const darkTheme = 'dark';

export type ThemeContextType = {
	theme: string;
	bgColor: string;
	revGradient: string | null;
	gradient: string | null;
	success: boolean;
	triggerSuccess: () => void;
	toggleTheme: () => void;
};

export const ThemeContext =
	React.createContext<ThemeContextType | undefined>(undefined);

type Props = {
	children: React.ReactNode;
};
export const ThemeProvider = ({ children }: Props): JSX.Element => {
	const [cookies, setCookie] = useCookies([cookieName]);
	// const [theme, setTheme] = React.useState(cookies[cookieName] || 'dark');
	const [theme, setTheme] = React.useState('dark');
	const [success, setSuccess] = React.useState<boolean>(false);
	const triggerSuccess = () => {
		setSuccess(true);
		setTimeout(() => {
			setSuccess(false);
		}, 30000);
	};
	const toggleTheme = () => {
		const set = theme === 'light' ? 'dark' : 'light';
		setTheme(set);
		setCookie(cookieName, set);
	};
	React.useEffect(() => {
		// const currentTheme = cookies[cookieName] || 'dark';
		const currentTheme = 'dark';
		setTheme(currentTheme);
	}, [cookies]);
	const [bgColor, setBgColor] = React.useState<string>('#FFFFFF');
	const [gradient, setGradient] = React.useState<string | null>(null);
	const [revGradient, setRevGradient] = React.useState<string | null>(null);
	React.useEffect(() => {
		if (theme) {
			switch (theme) {
				case 'light':
					setBgColor('#292929');
					setGradient(
						`background: rgb(3,146,252);
						background: linear-gradient(0deg, rgba(3,146,252,0.75) 0%, rgba(51,51,51,0.75) 0%, rgba(86,86,86,0.75) 100%, rgba(92,92,92,0.75) 100%);`
					);
					setRevGradient(
						`background: rgb(38,38,38);
						background: linear-gradient(0deg, rgba(38,38,38,0.75) 0%, rgba(73,73,73,0.75) 100%);`
					);
					break;
				default:
				case 'dark':
					setBgColor('#0A0A0A');
					setGradient(
						`background: rgb(0,0,0);
						background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(43,43,43,0.75) 76%, rgba(73,73,73,0.75) 100%);` //done
					);
					setRevGradient(
						`background: rgb(73,73,73);
						background: linear-gradient(0deg, rgba(73,73,73,0.75) 0%, rgba(43,43,43,0.75) 23%, rgba(0,0,0,0.75) 100%);` //done
					);
					break;
			}
		}
	}, [theme]);

	return (
		<ThemeContext.Provider
			value={{
				theme,
				bgColor,
				success,
				triggerSuccess,
				revGradient,
				gradient,
				toggleTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = (): ThemeContextType | undefined =>
	React.useContext(ThemeContext);
