import {
	useTheme,
	useTitle,
	Section,
	InnerSection,
	H1,
	Paragraph,
	icons,
	Button,
	UnstyledLink,
} from '../index';

export const NoMatch404: React.FunctionComponent = () => {
	const { theme } = useTheme()!;
	const Icon = icons.AiFillHome;
	useTitle({ page: '404 Page Not Found' });
	return (
		<Section theme={theme}>
			<InnerSection>
				<H1>404 Page Not Found</H1>
				<Paragraph>
					Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
					existed in the first place?
				</Paragraph>
				<br />
				<br />
				<div
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
					}}
				>
					<UnstyledLink to={'/'}>
						<Button>
							<Icon /> Go to the Home Page
						</Button>
					</UnstyledLink>
				</div>
			</InnerSection>
		</Section>
	);
};
