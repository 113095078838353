import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { utils } from 'ethers';
import styled from 'styled-components';
import { useBalance } from '../hooks/index';
import { targetNetwork, constants } from '../constants';

export const ExternalLink = styled.a`
	text-decoration: none;
	color: #eee;
	transition: 1s ease all;
	border-bottom: 1px solid #fff;
	&:hover {
		color: #fff;
		border-bottom: 1px dotted #fff;
	}
`;

const BalanceContainer = styled.div`
	font-size: 0.75rem;
	font-family: 'Courier New', Courier, monospace;
`;

export const Balance: React.FunctionComponent = () => {
	const { account } = useWeb3React('metamask');
	const { library } = useWeb3React('node');
	// const [balance, setBalance] = React.useState<any>();
	const [refresh, setRefresh] = React.useState<boolean>(false);
	//onClick will trigger the balance to refresh (for when it doesnt load)
	const onClick = () => setRefresh(!refresh);
	const balance = useBalance(library, account!, constants.pollerInterval);
	// ensures refresh if referential identity of library doesn't change across chainIds
	return (
		<BalanceContainer>
			<b>Balance:</b>
			<span style={{ padding: '0px 7px 0px 2px' }}>
				{balance === null ? (
					<span style={{ cursor: 'pointer' }} onClick={onClick}>
						Update
					</span> //an error occurred
				) : balance ? (
					`Ξ${Number(utils.formatEther(balance)).toFixed(8)}`
				) : (
					''
				)}
			</span>

			<ExternalLink
				href={`${targetNetwork.blockExplorer}address/${account}`}
				target='_BLANK'
			>
				<b>Explorer</b>
			</ExternalLink>
		</BalanceContainer>
	);
};
