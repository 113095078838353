import { HiOutlineViewGridAdd } from 'react-icons/hi';
// import { MdLibraryBooks } from 'react-icons/md';
import { NETWORKS } from './networks';

//set the target network
export const targetNetwork = NETWORKS.mainnet;

export const constants = {
	token: {
		symbol: 'THO',
		maxSupply: 6666,
		presaleSupply: 4444,
	},
	logo: `The Holy Ones`,
	footer: `Join`,
	projectName: `The Holy Ones`,
	projectHomepage: `https://theholyones.io`,
	baseCurrency: 'ETH',
	supportedChainIds: [targetNetwork.chainId],
	socials: {
		atlasDiscord: '84GEXy3UxM',
		discord: 'holyones',
		twitter: 'theholyonesnft',
		instagram: 'theholyones.nft',
		opensea: 'theholyones-nft',
	},
	shareTweet: {
		text: 'I just minted a @theholyonesnft! ',
		url: 'https://mint.theholyones.io',
		hashtags: ['theholyones', 'holyones', 'NFTCollector', 'NFT'],
	},
	fonts: {
		title: 'Poppins',
		body: 'Poppins',
	},
	cookiePrefix: 'atlas',
	navMenu: [
		{
			path: '/',
			text: 'Mint',
			icon: HiOutlineViewGridAdd,
			include: ['sidebar', 'navbar'],
		},
		// {
		// 	path: '/provenance',
		// 	text: 'Provenance',
		// 	icon: MdLibraryBooks,
		// 	include: ['sidebar', 'navbar'],
		// },
	],
	pollerInterval: 25000,
};
