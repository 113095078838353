import { createGlobalStyle } from 'styled-components';
import { useTheme } from '../index';
// import font1_woff from '../../fonts/MuseoSansRounded-100.woff';
// import font1_woff2 from '../../fonts/MuseoSansRounded-100.woff2';
// import font2_woff from '../../fonts/DIN-Black.woff';
// import font2_woff2 from '../../fonts/DIN-Black.woff2';
// @font-face {
//   font-family: '${constants.fonts.body}';
//   src:  url(${font1_woff2}) format('woff2'),
//     url(${font1_woff}) format('woff');
// }
// @font-face {
//   font-family: '${constants.fonts.title}';
//   src:  url(${font2_woff2}) format('woff2'),
//     url(${font2_woff}) format('woff');
// }
import { constants } from '../constants';

export const GlobalStyleElem: any = createGlobalStyle`
  body {
    font-family: '${constants.fonts.body}', sans-serif;
    margin: 0px;
    overflow-X: hidden;
    transition: all 4s ease 0;
    background-color: ${({ colorHex }: { colorHex: string }) =>
			colorHex ? colorHex : '#79b4e0'};
  }
  .text-center {
    text-align: center;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .navbar-link-active {
    border-bottom: 4px solid #c43321;
  }
  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;

export const GlobalStyle: React.FunctionComponent = () => {
	const { theme, bgColor } = useTheme()!;

	return <GlobalStyleElem colorHex={bgColor} theme={theme} />;
};
