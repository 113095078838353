import { useContext } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
	EthereumContext,
	Balance,
	Blockie,
	FlexRow,
	FlexColumn,
} from '../index';
import { useTheme } from '../contexts';
import { colors } from '../constants';

const Alert = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 3px;
	color: white;
	transition: 2s ease-in-out all;
	border-bottom: 3px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
`;

const AddressElem = styled.p`
	font-family: 'Courier New', Courier, monospace;
	margin: 0;
	font-weight: 600;
	font-size: 0.8rem;
`;

const DisconnectBtn = styled.button`
	margin: 0;
	padding: 0;
	background-color: white;
	color: ${({ colorHex }: { colorHex: string }) =>
		colorHex ? colorHex : '#79b4e0'};
	border: 0;
	border-radius: 6px;
	padding: 2px 6px;
	transition: 0.3s ease-in-out all;
	box-sizing: border-box;
	border: 1px solid white;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	&:hover {
		background-color: ${({ colorHex }: { colorHex: string }) =>
			colorHex ? colorHex : '#79b4e0'};
		color: white;
		border: 1px solid white;
	}
`;

function addressConcat(addr: string): string {
	const l = addr.length,
		n = 12;
	return addr.substring(0, n) + '...' + addr.substring(l - n, l);
}

const Address = ({ address }: { address: string | null | undefined }) => {
	const { logout } = useContext(EthereumContext);
	const { bgColor } = useTheme()!;
	return address ? (
		<FlexRow
			style={{
				alignItems: 'center',
			}}
		>
			<div
				style={{
					paddingRight: 10,
				}}
			>
				<Blockie address={address} />
			</div>
			<FlexColumn style={{ marginRight: 10 }}>
				<AddressElem>{addressConcat(address)}</AddressElem>
				<div>
					<Balance />
				</div>
			</FlexColumn>
			<DisconnectBtn
				colorHex={bgColor}
				onClick={() => {
					toast.dark('Disconnected from MetaMask', { autoClose: 1500 });
					if (logout) {
						logout();
					}
				}}
			>
				Disconnect
			</DisconnectBtn>
		</FlexRow>
	) : (
		<></>
	);
};

export const TopAlert = (): JSX.Element => {
	const { theme } = useTheme()!;
	const { errorMsg, account } = useContext(EthereumContext);
	return account ? (
		<Alert className='noselect' theme={theme}>
			<Address address={account} />
		</Alert>
	) : errorMsg ? (
		<Alert theme={theme}>{errorMsg}</Alert>
	) : (
		<></>
	);
};
