import React from 'react';
import { icons } from '../constants';

interface Props {
	text: string;
}

export const Loading: React.FC<Props> = ({ text = '' }) => {
	const FaSpinner = icons.FaSpinner;
	return (
		<span>
			<FaSpinner className='icon-spin' style={{ marginRight: 5 }} /> Loading
			{text && ' ' + text}...
		</span>
	);
};
