import styled from 'styled-components';
import React from 'react';
import background from '../../images/holybg.png';
import logo from '../../images/logo-lg.png';
import { colors, EthereumContext, useTitle, Button, constants } from '../index';
import {
	getSaleStatus,
	Loading,
	ParticlesBackground,
	Status,
} from '../components';
import { targetNetwork } from '../constants';

const ConnectPageSection = styled.div`
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${background});
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

const ConnectFrame = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;

const LogoFrame = styled.div`
	transition: 0.2s ease all;
	margin-top: 20px;
	max-width: 400px;
	background-color: #ddd;
	border: 2px solid white;
	padding: 20px;
	border-radius: 5px;
	opacity: 0.8;
	transform: rotate(-5deg) scale(0.8);
	&:hover {
		opacity: 1;
		transform: rotate(0deg) scale(1);
	}
`;
const ConnectButtonFrame = styled.div`
	margin-top: 20px;
	max-width: 400px;
`;

const LogoImg = styled.img`
	width: 100%;
`;

const SaleStatus = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	font-weight: 500;
	font-size: 1.25rem;
`;

export const Connect: React.FunctionComponent = () => {
	useTitle({ page: `Mint` });
	const { contractState, connect } = React.useContext(EthereumContext);
	const [element, setElement] = React.useState<any>(
		getSaleStatus(-1, targetNetwork.chainId)
	);
	React.useEffect(() => {
		if (!contractState) return;
		const { saleState } = contractState!;
		if (saleState !== undefined && saleState !== null) {
			setElement(getSaleStatus(saleState, targetNetwork.chainId));
		}
	}, [contractState?.saleState]);
	return (
		<ConnectPageSection className='noselect'>
			<ParticlesBackground />
			<ConnectFrame>
				<LogoFrame>
					<LogoImg src={logo} alt={`${constants.projectName} Logo`} />
					<SaleStatus>{element && element}</SaleStatus>
				</LogoFrame>

				<ConnectButtonFrame>
					<Button
						whileTap={{ scale: 0.9 }}
						whileHover={{ scale: 1.1 }}
						onClick={connect}
					>
						Connect Wallet
					</Button>
				</ConnectButtonFrame>

				{/* Display remaining supply  */}
				<Status>
					{contractState &&
					contractState.MAX_SUPPLY &&
					contractState.supplyRemaining ? (
						`${
							contractState.supplyRemaining
						}/${contractState.MAX_SUPPLY.toNumber()} Remaining`
					) : (
						<Loading text='supply' />
					)}
				</Status>
			</ConnectFrame>
		</ConnectPageSection>
	);
};
