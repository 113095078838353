import { AiFillHome, AiFillInfoCircle } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { BsLink } from 'react-icons/bs';
import { GiHamburgerMenu, GiLever } from 'react-icons/gi';
import {
	FaRegLightbulb,
	FaLightbulb,
	FaDiscord,
	FaTwitterSquare,
	FaSpinner,
	FaInstagramSquare,
	FaEye,
	FaEyeSlash,
	FaListUl,
} from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { RiSailboatFill } from 'react-icons/ri';
export const icons = {
	AiFillHome,
	AiFillInfoCircle,
	BiError,
	FaRegLightbulb,
	FaLightbulb,
	FaSpinner,
	FaDiscord,
	FaTwitterSquare,
	FaEye,
	FaEyeSlash,
	FaListUl,
	GiHamburgerMenu,
	FiSend,
	GiLever,
	BsLink,
	FaInstagramSquare,
	RiSailboatFill,
};
