import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { targetNetwork } from '../constants';
import { EthereumContext, icons } from '../index';
import { Loading } from './Loading';

const StatusContainer = styled.div`
	font-size: 0.75rem;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const TextWrap = styled.span`
	transition: 0.2s ease-in-out all;
	background-color: white;
	padding: 7px 10px;
	border-radius: 8px;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
`;

export function getSaleStatus(status: number, chainId: number): any {
	const FaSpinner = icons.FaSpinner;
	const inTestnet = chainId !== 1;
	let element: any;
	switch (status) {
		default:
		case -1:
			//loading
			element = <Loading text='status' />;
			break;
		case 1:
			element = `Presale is live! ✅ ${inTestnet ? ' (TESTNET)' : ''}`;
			break;
		case 2:
			element = `Public sale is live! ✅ ${inTestnet ? ' (TESTNET)' : ''}`;
			break;
		case 3:
			element = `Sale is complete! Thank you HOLY FAM! 💖 ${
				inTestnet ? ' (TESTNET)' : ''
			}`;
			break;
		case 0:
			element = (
				<>
					Waiting for sale to go live {inTestnet ? ' (TESTNET)' : ''}{' '}
					&nbsp;&nbsp;
					<FaSpinner className='icon-spin' style={{ marginRight: 5 }} />
				</>
			);
			break;
	}
	return element;
}

export const SaleStatus: React.FunctionComponent = () => {
	const { contractState } = useContext(EthereumContext);
	const [element, setElement] = useState<any>(
		getSaleStatus(-1, targetNetwork.chainId)
	);
	useEffect(() => {
		if (!contractState) return;
		const { saleState } = contractState!;
		if (saleState !== undefined && saleState !== null) {
			setElement(getSaleStatus(saleState, targetNetwork.chainId));
		}
	}, [contractState?.saleState]);

	return <Status>{element && element}</Status>;
};

export const Status: React.FC = props => {
	return (
		<StatusContainer>
			<TextWrap>{props.children}</TextWrap>
		</StatusContainer>
	);
};
