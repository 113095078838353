import { BigNumber } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	colors,
	useTitle,
	EthereumContext,
	icons,
	useDebounce,
	FlexColumn,
	LazyImageCardTop,
	constants,
	contractInformation,
	targetNetwork,
	ParticlesBackground,
	log,
} from '../index';
import openseaLogo from '../../images/opensea.svg';
import ReactTooltip from 'react-tooltip';

const InventoryPageSection = styled.div`
	display: flex;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	border-bottom: 1px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

const InventoryFrame = styled.div`
	display: flex;
	flex-direction: column;
	width: 1250px;
	@media screen and (max-width: 1250px) {
		width: 100%;
	}
`;
const TokenImg = styled(LazyImageCardTop)`
	width: 285px;
	height: 285px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const InventoryTitleFrame = styled.div``;
const InventoryTitle = styled.h1`
	font-family: '${constants.fonts.title}';
	letter-spacing: 2px;
	text-align: center;
	color: white;
	font-weight: 900;
`;

const ShadowText = styled.span`
	text-shadow: 3px 1px 3px #000000, 3px 1px 12px #000000;
`;

const Button = styled(motion.button)``;
const MotionA = styled(motion.a)``;

export interface ITrait {
	trait_type: string;
	value: string;
}

export interface IToken {
	uri: string;
	data: {
		attributes: ITrait[];
		image: string;
	};
}

export const Inventory: React.FunctionComponent = () => {
	useTitle({ page: `${constants.token.symbol} Inventory` });
	const { account, contractState } = useContext(EthereumContext);

	const [tokenURIs, setTokenURIs] = useState<string[]>([]);
	useEffect(() => {
		async function go() {
			if (
				contractState &&
				contractState.tokensInWallet &&
				contractState.tokensInWallet.length > 0 &&
				contractState.baseURI &&
				account
			) {
				const _tokenURIs: string[] = [];

				[...contractState.tokensInWallet].forEach(
					async (tokenId: BigNumber) => {
						const tokenURI = `${contractState.baseURI}${tokenId.toString()}`;
						if (_tokenURIs.indexOf(tokenURI) === -1) _tokenURIs.push(tokenURI);
					}
				);
				setTokenURIs(_tokenURIs);
			}
		}
		go();
	}, [contractState!.baseURI, contractState!.tokensInWallet, account]);

	//when the tokenUris are ready then execute
	const debouncedTokenURIs = useDebounce<string[]>(tokenURIs, 1000);

	const [tokenData, setTokenData] = useState<IToken[]>([]);
	const fetchData = () => {
		const currentLength = tokenData.length;
		const totalLength = debouncedTokenURIs.length;
		const nextTarget = currentLength + 20;
		const slice = debouncedTokenURIs.slice(
			currentLength,
			nextTarget > totalLength ? totalLength : nextTarget
		);
		function getSlice(uri: string): Promise<IToken> {
			return new Promise((resolve, reject) => {
				axios
					.get(uri)
					.then((response: any) => {
						const { attributes, image } = response.data;
						const item = { uri, data: { attributes, image } };
						resolve(item);
					})
					.catch((err: any) => reject(`Error: ${err.message}`));
			});
		}
		const promises = [];
		for (let i = 0; i < slice.length; i++) {
			promises.push(getSlice(slice[i]));
		}
		Promise.all(promises)
			.then((newData: IToken[]) => {
				setTokenData([...tokenData, ...newData]);
			})
			.catch(error => {
				log(error);
			});
	};

	//on load fetch initial batch
	useEffect(() => {
		if (debouncedTokenURIs && debouncedTokenURIs.length > 0) {
			fetchData();
		}
	}, [debouncedTokenURIs]);

	const [hasMore, setHasMore] = useState<boolean>(true);
	useEffect(() => {
		if (
			contractState &&
			contractState.tokensInWallet &&
			contractState.tokensInWallet.length &&
			tokenData.length > 0
		) {
			setHasMore(contractState.tokensInWallet.length !== tokenData.length);
		} else if (
			contractState &&
			contractState.tokensInWallet &&
			contractState.tokensInWallet.length === 0
		) {
			setHasMore(false);
		}
	}, [contractState!.tokensInWallet, tokenData]);

	const FaSpinner = icons.FaSpinner;
	const FaTwitterSquare = icons.FaTwitterSquare;
	const FaListUl = icons.FaListUl;
	const onTweet = (tokenId: string, openseaUrl: string) =>
		window.open(
			`http://twitter.com/share?text=Check out my @${
				constants.socials.twitter
			} number ${tokenId}!! &url=${openseaUrl}&hashtags=${constants.shareTweet.hashtags.join(
				','
			)}
		`,
			'',
			'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600'
		);
	return (
		<InventoryPageSection className='noselect'>
			<ParticlesBackground />
			<FlexColumn>
				<InventoryFrame>
					<InventoryTitleFrame>
						<InventoryTitle>
							<ShadowText>{constants.token.symbol} Inventory</ShadowText>&nbsp;
							{contractState && contractState.tokensInWallet && account && (
								<small className={'badge rounded-pill bg-primary'}>
									{contractState.tokensInWallet.length}
								</small>
							)}
						</InventoryTitle>
					</InventoryTitleFrame>
				</InventoryFrame>

				{contractState &&
					contractState.tokensInWallet &&
					contractState.tokensInWallet.length > 0 && (
						<InventoryFrame>
							<InfiniteScroll
								dataLength={contractState.tokensInWallet.length}
								next={fetchData}
								hasMore={hasMore}
								hasChildren={true}
								scrollThreshold={0.5}
								loader={
									<div className='d-flex justify-content-center'>
										<Button
											onClick={fetchData}
											className='btn btn-secondary mt-5'
										>
											<span>
												<FaSpinner
													className='icon-spin'
													style={{ marginRight: 5 }}
												/>{' '}
											</span>
											Load more...
										</Button>
									</div>
								}
							>
								<div className='d-flex flex-wrap justify-content-center'>
									{tokenData.map((i, index) => {
										const s = i.uri.split('/');
										const tokenId = s[s.length - 1];
										const openseaUrl = `${
											targetNetwork.opensea
										}assets/${contractInformation.address.toLowerCase()}/${tokenId}`;
										return (
											<div
												className='card'
												style={{ width: '18rem', margin: 5 }}
												key={`card-${index}`}
											>
												<TokenImg
													src={i.data.image}
													alt={`Image for ${constants.projectName} Token #${tokenId}`}
												/>
												<div className='card-body'>
													<h5 className='card-title'></h5>
													<p className='card-text'>
														{constants.projectName} Token #{tokenId}
													</p>
													<MotionA
														href={openseaUrl}
														target='_BLANK'
														className='btn btn-secondary'
														whileTap={{ scale: 0.9 }}
														whileHover={{ scale: 1.1 }}
													>
														<img
															src={openseaLogo}
															style={{ height: 'auto', width: '80px' }}
														/>
													</MotionA>
													&nbsp;
													<MotionA
														href={i.uri}
														target='_BLANK'
														className='btn btn-secondary'
														whileTap={{ scale: 0.9 }}
														whileHover={{ scale: 1.1 }}
														data-tip
														data-for='metadata-desc'
													>
														<FaListUl />
													</MotionA>
													<ReactTooltip
														id='metadata-desc'
														place='top'
														type='dark'
														effect='float'
													>
														View MetaData
													</ReactTooltip>
													&nbsp;
													<Button
														className='btn btn-secondary'
														onClick={() => onTweet(tokenId, openseaUrl)}
														whileTap={{ scale: 0.9 }}
														whileHover={{ scale: 1.1 }}
														data-tip
														data-for='tweet-desc'
													>
														<FaTwitterSquare />
													</Button>
													<ReactTooltip
														id='tweet-desc'
														place='top'
														type='dark'
														effect='float'
													>
														Tweet about your item
													</ReactTooltip>
												</div>
											</div>
										);
									})}
								</div>
							</InfiniteScroll>
						</InventoryFrame>
					)}
			</FlexColumn>
		</InventoryPageSection>
	);
};
