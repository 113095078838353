import contractABI from './abi.json';

export const contractInformation: { address: string; abi: any[] } = {
	// address: '0x0Ebe91b18cd6777453d562ABee668d87FAB75595', //testnet v1
	// address: '0x4c9396a3f1d6bd3d7af3ae495577f670c0935482', //testnet v2
	// address: '0x2C554b28879d44f254491f32d277dd59aA7379a1', //testnet v3
	// address: '0xA74c0bAcEbC492305D05b9984962853F8a118326', //testnet v4
	// address: '0xc1F42411073731106318d6338644CC95081A51b4', //mainnet
	address: '0xf8a8db29A9fBA05aC4E36b8d7Df6D47D120247fD', //mainnet v2
	abi: contractABI,
};
