import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../index';
import quicknode from '../../images/quicknode.png';
import atlascorp from '../../images/atlascorp.png';
import { constants, icons } from '../index';
import { useTheme } from '../contexts';

const FooterNav = styled.nav`
	${({ gradient }: { gradient: string | null }) => gradient && gradient}
	color: white;
	border-top: 3px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.4s ease all;
	min-height: 70px;
	z-index: 10;
`;

const Link = styled(motion.a)`
	color: white;
	font-size: 25px;
	margin: 0px 3px;
	&:hover {
		color: #c43321;
	}
`;

const QuicknodeImg = styled.img`
	filter: invert(100%);
	height: auto;
	width: 125px;
`;
const AtlasCorpImg = styled.img`
	filter: invert(100%);
	height: auto;
	width: 175px;
`;

const FooterCol = styled.div`
	margin-top: 12px;
	margin-bottom: 12px;
	display: flex;
`;

const FlexCenter = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export const Footer: React.FunctionComponent = () => {
	const TwitterIcon = icons.FaTwitterSquare;
	const DiscordIcon = icons.FaDiscord;
	const InstagramIcon = icons.FaInstagramSquare;
	const OpenseaIcon = icons.RiSailboatFill;
	// const { theme, toggleTheme, revGradient } = useTheme()!;
	const { revGradient } = useTheme()!;
	// const FaLightbulb = icons.FaLightbulb;
	// const FaLightbulbReg = icons.FaRegLightbulb;
	return (
		<FooterNav className='noselect' gradient={revGradient}>
			<div className='container' style={{ margin: 20 }}>
				<div className='row text-center w-100 py-xs-3'>
					<FooterCol className='col-12 col-lg-4 order-lg-first'>
						<FlexCenter>
							<span>
								Powered by{' '}
								<Link
									href={`https://www.quicknode.com/`}
									whileTap={{ scale: 0.8 }}
									whileHover={{ scale: 1.15 }}
									target='_BLANK'
									rel='noreferrer'
								>
									<QuicknodeImg src={quicknode} alt='QuickNode' />
								</Link>
							</span>
						</FlexCenter>
					</FooterCol>
					<FooterCol className='col-12 col-lg-4 order-first my-sm-2'>
						<FlexCenter>
							<Link
								href={`https://twitter.com/${constants.socials.twitter}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<TwitterIcon />
							</Link>

							<Link
								href={`https://instagram.com/${constants.socials.instagram}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<InstagramIcon />
							</Link>

							<Link
								href={`https://discord.gg/${constants.socials.discord}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<DiscordIcon />
							</Link>

							<Link
								href={`https://opensea.io/collection/${constants.socials.opensea}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<OpenseaIcon />
							</Link>

							{/* <Link
								onClick={toggleTheme}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
							>
								{theme === 'light' ? <FaLightbulb /> : <FaLightbulbReg />}
							</Link> */}
						</FlexCenter>
					</FooterCol>
					<FooterCol className='order-last col-lg-4 col-sm-12 col-12'>
						<FlexCenter>
							<span>
								Developed in 2022 by
								<Link
									href={`https://discord.gg/${constants.socials.atlasDiscord}`}
									whileTap={{ scale: 0.8 }}
									whileHover={{ scale: 1.15 }}
									target='_BLANK'
									rel='noreferrer'
								>
									<AtlasCorpImg src={atlascorp} alt='Atlas Corporation' />
								</Link>
							</span>
						</FlexCenter>
					</FooterCol>
				</div>
			</div>
		</FooterNav>
	);
};
