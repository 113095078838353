import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink as RLink } from 'react-router-dom';
import {
	constants,
	Container,
	colors,
	topLogoAnim,
	useTheme,
	icons,
} from '../index';
import logo2 from '../../images/logo-sm.png';

const Nav = styled.nav`
	position: relative;
	height: 100px;
	${({ gradient }: { gradient: string | null }) => gradient && gradient}
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.nav.dark : colors.text.nav.light};
	border-bottom: 3px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	display: flex;
	justify-content: center;
	transition: 0.4s ease all;
	z-index: 10;
`;

const Link = styled.a`
	padding-top: 1.5rem;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	animation: 2s ${topLogoAnim};
	margin: 0;
	transition: 0.4s ease all;
	z-index: 1;
	display: flex;
	@media screen and (max-width: 600px) {
		padding-top: 22px;
	}
`;

const NavLogo = styled.img`
	transition: 0.4s ease all;
	@media screen and (max-width: 600px) {
		padding-top: 5px;
	}
`;

const NavTextLogoPlaceholder = styled.div`
	margin-right: auto;
`;

const NavMenu = styled.ul`
	margin: auto;
	list-style: none;
	white-space: nowrap;
	padding-right: 30px;
	@media screen and (max-width: 600px) {
		display: none;
	}
`;

const NavMenuItem = styled(motion.li)`
	padding: 0px 8px 0px 8px;
	display: inline-block;
	float: none;
	font-size: 0.9rem;
	@media screen and (max-width: 600px) {
		font-size: 1rem;
	}
`;

const MenuLink = styled(RLink)`
	text-decoration: none;
	color: white;
	display: flex;
	flex-direction: row;
	&:hover {
		color: #c43321;
		/* transform: translateY(-2px); */
	}
`;

const HamburgerIconContainer = styled.div`
	display: none;
	@media screen and (max-width: 600px) {
		display: block;
	}
	font-size: 16px;
	padding: 10px;
	cursor: pointer;
	&:hover {
		color: #c43321;
	}
`;

const NavbarIconsContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-right: 30px;
	align-items: center;
`;

const StyledIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
	margin-bottom: 2px;
`;

const HamburgerIcon = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
	const Icon = icons.GiHamburgerMenu;
	return (
		<HamburgerIconContainer onClick={toggleSidebar}>
			<Icon />
		</HamburgerIconContainer>
	);
};

interface INavbar {
	sidebarVisible: boolean;
	toggleSidebar: () => void;
}

export const Navbar = ({
	sidebarVisible,
	toggleSidebar,
}: INavbar): JSX.Element => {
	const { theme, gradient } = useTheme()!;
	return (
		<Nav theme={theme} gradient={gradient}>
			<Link href={constants.projectHomepage} title='Go to the homepage'>
				<NavLogo src={logo2} alt={`${constants.projectName} Logo`} />
			</Link>
			<Container>
				<NavTextLogoPlaceholder />
				<div
					style={{
						display: 'flex',
					}}
				>
					<NavbarIconsContainer>
						<HamburgerIcon toggleSidebar={toggleSidebar} />
					</NavbarIconsContainer>
					<NavMenu>
						{!sidebarVisible &&
							constants.navMenu.length &&
							constants.navMenu
								.filter(item => item.include.indexOf('navbar') > -1)
								.map((c, i) => {
									const Icon = c.icon;
									return (
										<NavMenuItem
											whileTap={{ scale: 0.8 }}
											whileHover={{ scale: 1.2 }}
											key={`nav-menu-${i}`}
										>
											<MenuLink
												{...(c.path === '/' ? { exact: true } : {})}
												theme={theme}
												to={c.path}
												activeClassName={'navbar-link-active'}
												className={'noselect'}
											>
												<StyledIcon>
													<Icon />
												</StyledIcon>{' '}
												{c.text}
											</MenuLink>
										</NavMenuItem>
									);
								})}
					</NavMenu>
				</div>
			</Container>
		</Nav>
	);
};
