import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';

interface IAnimatedRoutes {
	children: React.ReactNode;
	exitBeforeEnter?: boolean | undefined;
	initial?: boolean | undefined;
}

export const AnimatedRoutes = ({
	children,
	exitBeforeEnter = true,
	initial = false,
}: IAnimatedRoutes): JSX.Element => {
	const location = useLocation();
	return (
		<AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
			<Switch location={location} key={location.pathname}>
				{children}
			</Switch>
		</AnimatePresence>
	);
};

interface IMountTransition {
	children: React.ReactNode;
	slide?: number | undefined;
	slideUp?: number | undefined;
}

export const MountTransition = ({
	children,
	slide = 0,
	slideUp = 0,
}: IMountTransition): JSX.Element => (
	<motion.div
		exit={{ opacity: 0, x: slide, y: slideUp }}
		initial={{ opacity: 0, x: slide, y: slideUp }}
		animate={{ opacity: 1, x: 0, y: 0 }}
		onAnimationComplete={() => {
			//console.log('done');
		}}
		style={{
			display: 'flex',
			flex: 1,
		}}
	>
		{children}
	</motion.div>
);

interface IRouteTransition {
	children: React.ReactNode;
	path: string | undefined;
	exact?: boolean | undefined;
	slide?: number | undefined;
	slideUp?: number | undefined;
}

export const RouteTransition = ({
	children,
	path,
	exact = false,
	slide = 0,
	slideUp = 0,
	...rest
}: IRouteTransition): JSX.Element => (
	<Route path={path} exact={exact} {...rest}>
		<MountTransition slide={slide} slideUp={slideUp}>
			{children}
		</MountTransition>
	</Route>
);
