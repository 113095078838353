export const colors = {
	background: {
		nav: {
			dark: '#002642',
			light: '#8EE3F5',
		},
		section: {
			dark: '#000C14',
			light: '#ECFBFD',
		},
		card: {
			dark: '#003152',
			light: '#90E8F3',
		},
	},
	text: {
		nav: {
			dark: '#ECFBFD',
			light: '#000C14',
		},
		section: {
			dark: '#ECFBFD',
			light: '#000C14',
		},
	},
	borders: {
		dark: '#c43321',
		light: '#c43321',
	},
};
