import React from 'react';
import { render } from 'react-dom';
import { createWeb3ReactRoot } from '@web3-react/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import {
	GlobalStyle,
	EthereumProvider,
	ThemeProvider,
	getLibrary,
} from './common';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const rootElement = document.getElementById('root');
const Web3ReactProvider = createWeb3ReactRoot('metamask');
const Web3ReactProviderReloaded = createWeb3ReactRoot('node');
render(
	<Router>
		<Web3ReactProvider getLibrary={getLibrary}>
			<Web3ReactProviderReloaded getLibrary={getLibrary}>
				<EthereumProvider>
					<ThemeProvider>
						<GlobalStyle />
						<App />
						<ToastContainer position='bottom-right' />
					</ThemeProvider>
				</EthereumProvider>
			</Web3ReactProviderReloaded>
		</Web3ReactProvider>
	</Router>,
	rootElement
);
