import styled from 'styled-components';
import { Loading, UnstyledA } from '../components';
import {
	colors,
	useTitle,
	Divider,
	Paragraph,
	useIsMobile,
	contractInformation,
	targetNetwork,
	icons,
	ParticlesBackground,
	Button,
	constants,
	EthereumContext,
} from '../index';
import React from 'react';

const ProveananceSection = styled.div`
	display: flex;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
	@media screen and (max-width: 600px) {
		padding: 15px;
	}
`;

const ProveananceFrame = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	width: 1200px;
	padding: 20px;
	border-radius: 16px;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	@media screen and (max-width: 1300px) {
		width: 100%;
	}
`;

const TitleFrame = styled.div``;
const InfoFrame = styled.div``;
const ConcatenatedFrame = styled.div``;
const ProvenanceRecordFrame = styled.div``;

const ProvenanceIFrame = styled.iframe`
	overflow-x: hidden;
`;

const ButtonFrame = styled.div`
	/* background-color: pink; */
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Provenance: React.FunctionComponent = () => {
	useTitle({ page: 'Proveanance' });
	//adjust heigght of iframe based upon content size
	const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
		input !== null && input.tagName === 'IFRAME';

	const adjustHeight = () => {
		const iframe = document.getElementById('prov-frame');
		if (isIFrame(iframe) && iframe.contentWindow) {
			iframe.style.height =
				iframe.contentWindow.document.body.scrollHeight + 40 + 'px'; // eslint-disable-line
		}
	};
	const { contractState } = React.useContext(EthereumContext)!;
	const isMobile = useIsMobile();
	const BsLink = icons.BsLink;
	return (
		<ProveananceSection>
			<ParticlesBackground />
			<ProveananceFrame>
				<TitleFrame>
					<Divider title='Proveanance' />
					<Paragraph>
						The following is the provenance records used to guarantee the
						integrity of each and every {constants.projectName} and the fairness
						of the sale. Each {constants.projectName} image is hashed using the
						SHA-256 algorithm to generate a hash - you can test this hash
						yourself to ensure that the image you're seeing matches the output
						and we encourage you to do so. A second hash is obtained when the
						image is loaded to IPFS - this further verifies that the image has
						not been tampered with since its creation. We concatenate each
						SHA-256 image hash together to obtain a combined hash, which we hash
						again to create the Contract Provenance. The Contract Provenance is
						made available at the start of the sale and is stored on the
						contract, whereas the individual image hashes will be made available
						after the sale has concluded.
					</Paragraph>
				</TitleFrame>

				<InfoFrame>
					<Divider title='Important Information' />
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ maxWidth: 500 }}>
							<table className='table table-bordered table-hover table-sm'>
								<tbody>
									<tr>
										<td>
											<b>Contract Provenance:</b>
										</td>
										<td
											className='text-truncate'
											style={{ maxWidth: 'calc( 50 * 1vw )' }}
										>
											{contractState && contractState.PROVENANCE ? (
												<span>
													<code>
														<pre>{contractState.PROVENANCE}</pre>
													</code>
													&nbsp;
													<UnstyledA
														title='Verify this value on the blockchain: Read contract property #3'
														target='_BLANK'
														rel='noreferrer'
														href={`${targetNetwork.blockExplorer}address/${contractInformation.address}#readContract`}
													>
														<BsLink />
													</UnstyledA>
												</span>
											) : (
												<Loading text='from blockchain' />
											)}
										</td>
									</tr>
									<tr>
										<td>
											<b>Contract Address:</b>
										</td>
										<td
											className='text-truncate'
											style={{ maxWidth: 'calc( 50 * 1vw )' }}
										>
											<UnstyledA
												target='_BLANK'
												rel='noreferrer'
												href={`${targetNetwork.blockExplorer}address/${contractInformation.address}`}
											>
												<code>
													<pre>{contractInformation.address}</pre>
												</code>
											</UnstyledA>
										</td>
									</tr>
									<tr>
										<td>
											<b>Network:</b>
										</td>
										<td>
											<code>
												{targetNetwork.name} (chainID: {targetNetwork.chainId})
											</code>
										</td>
									</tr>
									<tr>
										<td colSpan={2} className='text-center'>
											<BsLink /> Values per the smart contract
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</InfoFrame>

				<ConcatenatedFrame>
					<Divider title='Concatenated Hash String' />
					{isMobile ? (
						<ButtonFrame>
							<a href='hashes.html' target='_BLANK' rel='noreferrer'>
								<Button whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
									Open in new window
								</Button>
							</a>
						</ButtonFrame>
					) : (
						<ProvenanceIFrame
							id='hash-frame'
							title='Concatenated Hash String Frame'
							src='/hashes.html'
							width='100%'
						></ProvenanceIFrame>
					)}
				</ConcatenatedFrame>

				<ProvenanceRecordFrame>
					<Divider title='Provenance Record' />
					{isMobile ? (
						<ButtonFrame>
							<a href='provenance.html' target='_BLANK' rel='noreferrer'>
								<Button whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
									Open in new window
								</Button>
							</a>
						</ButtonFrame>
					) : (
						<ProvenanceIFrame
							id='prov-frame'
							title='Provenance Record Frame'
							src='/provenance.html'
							width='100%'
							onLoad={adjustHeight}
						></ProvenanceIFrame>
					)}
				</ProvenanceRecordFrame>
			</ProveananceFrame>
		</ProveananceSection>
	);
};
