import React from 'react';
import Particles from 'react-tsparticles';
import styled from 'styled-components';
import { useTheme } from '..';

const StyledParticles = styled(Particles)`
	position: fixed;
	background: #00356b;
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: -1;
	@media (max-width: 700px) {
		height: 100%;
	}
`;

const Space = () => {
	return (
		<StyledParticles
			options={{
				background: {
					color: {
						value: '#000',
					},
				},
				backgroundMask: {
					cover: {
						color: {
							value: '#fff',
						},
						opacity: 0.5,
					},
					enable: false,
				},
				detectRetina: true,
				fpsLimit: 60,
				infection: {
					cure: false,
					delay: 0,
					enable: false,
					infections: 0,
					stages: [],
				},
				interactivity: {
					detectsOn: 'canvas',
					events: {
						onClick: {
							enable: true,
							mode: [],
						},
						onHover: {
							enable: false,
							mode: [],
							parallax: {
								enable: true,
								force: 50,
								smooth: 10,
							},
						},
						resize: true,
					},
					modes: {
						attract: {
							distance: 200,
							duration: 0.4,
							speed: 1,
						},
						bubble: {
							distance: 200,
							duration: 0.4,
						},
						connect: {
							distance: 5,
							links: {
								opacity: 0.5,
							},
							radius: 90,
						},
						grab: {
							distance: 100,
							links: {
								opacity: 0.5,
							},
						},
						push: {
							quantity: 4,
						},
						remove: {
							quantity: 2,
						},
						repulse: {
							distance: 200,
							duration: 0.4,
							speed: 1,
						},
						slow: {
							factor: 3,
							radius: 200,
						},
						trail: {
							delay: 1,
							quantity: 1,
						},
					},
				},
				particles: {
					collisions: {
						enable: true,
						mode: 'bounce',
					},
					color: {
						value: '#fff',
						animation: {
							enable: false,
							speed: 1,
							sync: true,
						},
					},
					links: {
						blink: false,
						color: {
							value: '#fff',
						},
						consent: false,
						distance: 200,
						enable: true,
						opacity: 0.5,
						shadow: {
							blur: 5,
							color: {
								value: '#00ff00',
							},
							enable: false,
						},
						triangles: {
							enable: false,
						},
						width: 1,
						warp: false,
					},
					move: {
						angle: 90,
						attract: {
							enable: false,
							rotate: {
								x: 3000,
								y: 3000,
							},
						},
						direction: 'none',
						enable: true,
						noise: {
							delay: {
								random: {
									enable: false,
									minimumValue: 0,
								},
								value: 0,
							},
							enable: false,
						},
						outMode: 'out',
						random: false,
						speed: 2,
						straight: false,
						trail: {
							enable: false,
							length: 10,
							fillColor: {
								value: '#000000',
							},
						},
						vibrate: false,
						warp: false,
					},
					number: {
						density: {
							enable: false,
							area: 800,
							factor: 1000,
						},
						limit: 200,
						value: 50,
					},
					opacity: {
						animation: {
							enable: false,
							minimumValue: 0,
							speed: 2,
							sync: false,
						},
						random: {
							enable: false,
							minimumValue: 1,
						},
						value: 0.5,
					},
					rotate: {
						animation: {
							enable: false,
							speed: 0,
							sync: false,
						},
						direction: 'clockwise',
						path: false,
						random: false,
						value: 0,
					},
					shadow: {
						blur: 0,
						color: {
							value: '#000000',
						},
						enable: false,
						offset: {
							x: 0,
							y: 0,
						},
					},
					shape: {
						options: {},
						type: 'circle',
					},
					size: {
						animation: {
							destroy: 'none',
							enable: false,
							minimumValue: 0,
							speed: 5,
							startValue: 'max',
							sync: false,
						},
						random: {
							enable: false,
							minimumValue: 1,
						},
						value: 2,
					},
					stroke: {
						width: 0,
					},
					twinkle: {
						lines: {
							enable: false,
							frequency: 0.05,
							opacity: 0.5,
						},
						particles: {
							enable: false,
							frequency: 0.05,
							opacity: 0.5,
						},
					},
				},
				pauseOnBlur: true,
			}}
		/>
	);
};

const Confetti = () => {
	return (
		<StyledParticles
			options={{
				background: {
					color: {
						value: '#000000',
					},
				},
				fullScreen: {
					zIndex: -1,
				},
				particles: {
					bounce: {
						horizontal: {
							value: 0,
						},
						vertical: {
							value: 0,
						},
					},
					color: {
						value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
						animation: {
							h: {
								enable: true,
								speed: 30,
							},
						},
					},
					move: {
						decay: 0.1,
						direction: 'top',
						enable: true,
						gravity: {
							enable: true,
							maxSpeed: 200,
						},
						outModes: {
							default: 'destroy',
							bottom: 'bounce',
							left: 'destroy',
							right: 'destroy',
							top: 'none',
						},
						speed: {
							min: 50,
							max: 150,
						},
					},
					number: {
						limit: 300,
						value: 0,
					},
					opacity: {
						animation: {
							speed: 0.3,
							sync: true,
							destroy: 'min',
							startValue: 'max',
						},
					},
					roll: {
						darken: {
							enable: true,
							value: 30,
						},
						enable: true,
						enlighten: {
							enable: true,
							value: 30,
						},
						speed: {
							min: 15,
							max: 25,
						},
					},
					rotate: {
						value: {
							min: 0,
							max: 360,
						},
						animation: {
							enable: true,
							speed: 60,
						},
						direction: 'random',
					},
					shape: {
						options: {
							polygon: [
								{
									sides: 5,
								},
								{
									sides: 6,
								},
							],
							character: [
								{
									value: ['💖', '🤑', '🍀', '💎'],
								},
							],
						},
						type: [
							'circle',
							'square',
							'polygon',
							'character',
							'character',
							'character',
						],
					},
					tilt: {
						value: {
							min: 0,
							max: 360,
						},
						animation: {
							enable: true,
							speed: 60,
						},
						direction: 'random',
						enable: true,
					},
					wobble: {
						distance: 30,
						enable: true,
						speed: {
							min: -15,
							max: 15,
						},
					},
				},
				emitters: {
					autoPlay: true,
					fill: true,
					life: {
						wait: false,
					},
					rate: {
						quantity: 10,
						delay: 0.1,
					},
					shape: 'square',
					startCount: 0,
					size: {
						mode: 'percent',
						height: 0,
						width: 0,
					},
					position: {
						x: 50,
						y: 100,
					},
				},
			}}
		/>
	);
};

export const ParticlesBackground = (): JSX.Element => {
	const { success } = useTheme()!;
	return success ? <Confetti /> : <Space />;
};
