import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { slideInLeft, slideInUp } from 'react-animations';
import { colors, constants } from '../index';
import { Link as RLink } from 'react-router-dom';

export const topLogoAnim = keyframes`${slideInLeft}`;
export const bottomLogoAnim = keyframes`${slideInUp}`;

export const Container = styled.div`
	width: 1200px;
	transition: 0.2s ease all;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
	display: flex;
`;

export const Section = styled.section`
	display: flex;
	padding: 45px;
	min-height: 300px;
	width: 100%;
	border-bottom: 1px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	justify-content: center;
	transition: 0.4s ease all;
	background-color: ${({ theme }: { theme: string }) =>
		theme === 'dark'
			? colors.background.section.dark
			: colors.background.section.light};
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

export const InnerSection = styled.div`
	width: 710px;
	transition: 0.2s ease all;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 700px) {
		width: 100%;
	}
`;

export const H1 = styled.h1`
	font-family: 'Anton', sans-serif;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1.25px;
	transition: 1s ease all;
	@media screen and (max-width: 600px) {
		font-size: 1.5rem;
		letter-spacing: 1px;
	}
`;

export const Paragraph = styled.p``;

// export const ButtonElem = styled(motion.button)`
// 	transition: ease all 0.2s;
// 	cursor: pointer;
// 	padding: 10px 10px;
// 	outline: none;
// 	cursor: pointer;
// 	border-radius: 8px;
// 	border: 0;
// 	width: 100%;
// 	text-decoration: none;
// 	background-color: ${({ theme }: { theme: string }) =>
// 		theme === 'dark' ? '#333' : 'white'};
// 	color: ${({ theme }: { theme: string }) =>
// 		theme === 'dark' ? 'white' : '#333'};
// 	&:hover {
// 		background-color: ${({ theme }: { theme: string }) =>
// 			theme === 'dark' ? 'white' : '#333'};
// 		color: ${({ theme }: { theme: string }) =>
// 			theme === 'dark' ? 'black' : 'white'};
// 	}
// `;

// export const Button = ({
// 	children,
// }: {
// 	children: React.ReactNode;
// }): JSX.Element => {
// 	const { theme } = useTheme()!;
// 	return (
// 		<ButtonElem
// 			theme={theme}
// 			whileHover={{ scale: 1.15 }}
// 			whileTap={{ scale: 0.85 }}
// 		>
// 			{children}
// 		</ButtonElem>
// 	);
// };

export const UnstyledLink = styled(RLink)`
	text-decoration: none;
`;
export const UnstyledA = styled.a`
	text-decoration: none;
`;

const DividerElem = styled.div`
	transition: 0.5s ease-in-out all;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding-left: 45px;
	padding-right: 45px;
	margin-bottom: 5px;
	@media screen and (max-width: 600px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

export const Divider = ({
	title,
	color,
	shadow,
}: {
	title: string;
	color?: string;
	shadow?: boolean;
}): JSX.Element => {
	return (
		<DividerElem>
			<div style={{ height: '2px', flex: 1, backgroundColor: '#eee' }}></div>
			<h5
				style={{
					marginTop: 10,
					color: color ? color : 'black',
					fontFamily: constants.fonts.title,
					textShadow: shadow
						? '3px 1px 3px #000000, 3px 1px 12px #000000'
						: 'none',
					fontWeight: 900,
				}}
			>
				{title}
			</h5>
			<div style={{ height: '2px', flex: 1, backgroundColor: '#eee' }}></div>
		</DividerElem>
	);
};

export const BaseButton = styled(motion.button)`
	transition: 0.1s ease-in-out all;
	border-radius: 8px;
	padding: 10px 20px;
	min-width: 275px;
	width: 85%;
	background-color: #ff39ff;
	color: #fff8e5;
	border: 0;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	&:hover {
		background-color: #c43321;
		color: #white;
		font-weight: bold;
	}
	&:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}
`;

export const Button = styled(BaseButton)`
	background: rgb(196, 51, 33);
	background: linear-gradient(
		0deg,
		rgba(196, 51, 33, 1) 0%,
		rgba(235, 56, 34, 1) 100%
	);
	border: 2px solid white;
`;
